import { render, staticRenderFns } from "./OrderInvoices.vue?vue&type=template&id=9d819472&scoped=true&"
import script from "./OrderInvoices.vue?vue&type=script&lang=js&"
export * from "./OrderInvoices.vue?vue&type=script&lang=js&"
import style0 from "@@/core/components/order/OrderInvoices/OrderInvoicesStyle.css?vue&type=style&index=0&id=9d819472&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d819472",
  null
  
)

export default component.exports