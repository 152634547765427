import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload.vue";

import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BModal,
  VBModal,
  VBToggle,
  BSpinner,
  BForm,
} from "bootstrap-vue";

export default {
  name: "order",
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
    BSpinner,
    BForm,
    ExcelDownload,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/digipay-transactions",
      filter: {
        from_date: "",
        to_date: "",
        tracking_code: "",
        providerId: "",
        response_tracking_code: "",
        customerName: "",
        order_id: "",
      },
      fields: [
        {
          key: "id",
          label: "شناسه",
        },

        {
          key: "customerName",
          label: "نام مشتری",
        },

        {
          key: "amount",
          label: "مبلغ",
        },
        {
          key: "providerId",
          label: "providerId",
        },
        {
          key: "tracking_code",
          label: "کدرهگیری ارسال شده",
        },
        {
          key: "response_tracking_code",
          label: "کد رهگیری دریافت شده",
        },

        {
          key: "created_at",
          label: "تاریخ ثبت",
          sortable: true,
        },
        {
          key: "detail",
          label: "جزئیات سفارش",
        },
      ],

      transactions: [],
    };
  },
  mounted() {
    this.loadItems();
  },
  computed: {
    extraQuery() {
      return {
        from_date: this.filter.from_date,
        to_date: this.filter.to_date,
        tracking_code: this.filter.tracking_code,
        providerId: this.filter.providerId,
        response_tracking_code: this.filter.response_tracking_code,
        customerName: this.filter.customerName,
        order_id: this.filter.order_id,
      };
    },
  },
  methods: {
    filterData() {
      this.extraQuery = {
        from_date: this.filter.from_date,
        to_date: this.filter.to_date,
        tracking_code: this.filter.tracking_code,
        providerId: this.filter.providerId,
        response_tracking_code: this.filter.response_tracking_code,
        customerName: this.filter.customerName,
        order_id: this.filter.order_id,
      };
      this.loadItems();
    },
  },
};
