import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  name: "Customers",
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/customers",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "first_name",
          label: "نام",
        },
        {
          key: "last_name",
          label: "نام خانوادگی",
        },
        {
          key: "mobile",
          label: "شماره همراه",
        },
        {
          key: "email",
          label: "ایمیل",
        },
        // {
        //   key: 'gender',
        //   label: 'جنسیت'
        // },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "show",
          label: "مشاهده",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      filter: {
        first_name: "",
        last_name: "",
        mobile: null,
        email: null,
        has_deposits: 0,
        has_transactions: 0,
        status: true,
        start_date: "",
        end_date: "",
      },
    };
  },
  mounted() {
    this.loadItems();
    this.filter.has_deposits = this.$route.query.has_deposits ? 1 : 0;
    this.filter.has_transactions = this.$route.query.has_transactions ? 1 : 0;
    this.filter.status = this.$route.query.status ? 1 : 0;
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.first_name,
        searchBy1: "first_name",
        search2: this.filter.last_name,
        searchBy2: "last_name",
        search3: this.filter.mobile,
        searchBy3: "mobile",
        search4: this.filter.email,
        searchBy4: "email",
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
        has_deposits: this.filter.has_deposits,
        has_transactions: this.filter.has_transactions,
        status: this.filter.status ? 1 : 0,
      };
    },
  },
  computed: {
    localExtraQuery() {
      return {
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
    },
  },
};
