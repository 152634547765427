import { getStatusLabel } from "@@/core/libraries/adminFunctions";
import { BModal, BFormGroup } from "bootstrap-vue";

export default {
  name: "OrderChangeStatus",
  components: {
    BModal,
    BFormGroup,
  },
  props: {
    disabled: Boolean,
    order: Object,
  },
  data() {
    return {
      orderStatus: null,
      noCharge: false,
      statusOptions: [
        {
          value: "delivered",
          title: getStatusLabel("delivered"),
        },
        {
          value: "in_progress",
          title: getStatusLabel("in_progress"),
        },

        {
          value: "new",
          title: getStatusLabel("new"),
        },
        {
          value: "wait_for_payment",
          title: getStatusLabel("wait_for_payment"),
        },
        {
          value: "canceled",
          title: getStatusLabel("canceled"),
        },
        {
          value: "failed",
          title: getStatusLabel("failed"),
        },
        {
          value: "in_examination",
          title: getStatusLabel("in_examination"),
        },
      ],
    };
  },
  methods: {
    updateOrderStatus() {
      let order = { ...this.order };
      this.orderStatus = {
        value: order.status,
        title: getStatusLabel(order.status),
      };
      this.$root.$emit("bv::show::modal", "change-status");
    },
    async updatedOrderStatus() {
      try {
        this.$parent.disabled = true;
        const response = await this.$axios.post(
          `admin/orders/${this.order.id}/status`,
          {
            status: this.orderStatus ? this.orderStatus.value : "",
            no_charge: this.noCharge,
            _method: "put",
          }
        );
        this.order.status = this.orderStatus.value;
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "change-status");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.$parent.disabled = false;
      }
    },
  },
};
