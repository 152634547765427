import { getStatusLabel } from "@@/core/libraries/adminFunctions";
import Loading from "@@/core/components/shared/Loading/Loading";
import OrderStatus from "@@/core/components/order/OrderStatus/OrderStatus";
import OrderStatusesView from "@@/core/components/report/OrderStatusesView";
import mixins from "@@/core/mixins/mixins";
import WalletSvg from "@@/core/components/svgs/WalletSvg";
import dateFilterMixin from "@@/core/mixins/dateFilterMixin";

import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BModal,
  VBModal,
  VBToggle,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "order",
  components: {
    OrderStatusesView,
    Loading,
    OrderStatus,
    WalletSvg,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
    BSpinner,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  mixins: [mixins, dateFilterMixin],
  data() {
    return {
      params: {},
      url: "admin/orders_light",
      allSelected: false,
      widgets: [],
      btnStatus: false,
      selectedOrders: Array(100).fill(false),
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "id",
          label: "شناسه",
        },

        {
          key: "receiver",
          label: "گیرنده",
          sortable: true,
        },
        {
          key: "items_count",
          label: "تعداد آیتم",
          sortable: true,
        },
        // {
        //   key: "total_amount",
        //   label: "مبلغ کل",
        //   sortable: true,
        // },
        // {
        //   key: "tracking_code",
        //   label: "شماره پیگیری",
        // },
        {
          key: "status",
          label: "وضعیت",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
          sortable: true,
        },
        {
          key: "detail",
          label: "جزئیات",
        },
      ],
      order: {},
      shippings: [],
      gateways: [],
      filter: {
        id: "",
        tracking_code: "",
        city: "",
        province: "",
        product_id: "",
        variety_id: "",
        status: "",
        customer: null,
        start_date: "",
        end_date: "",
        shipping_id: "",
        first_name: "",
        last_name: "",
        gateway_name: "",
      },
      statusFilterOptions: [
        {
          value: "delivered",
          title: getStatusLabel("delivered"),
        },
        {
          value: "in_progress",
          title: getStatusLabel("in_progress"),
        },
        {
          value: "new",
          title: getStatusLabel("new"),
        },
        {
          value: "reserved",
          title: getStatusLabel("reserved"),
        },
        {
          value: "wait_for_payment",
          title: getStatusLabel("wait_for_payment"),
        },
        {
          value: "canceled",
          title: getStatusLabel("canceled"),
        },
        {
          value: "failed",
          title: getStatusLabel("failed"),
        },
        {
          value: "canceled_by_user",
          title: getStatusLabel("canceled_by_user"),
        },
      ],
      statusOptions: [
        {
          value: "in_progress",
          title: getStatusLabel("in_progress"),
        },
        {
          value: "delivered",
          title: getStatusLabel("delivered"),
        },
        {
          value: "new",
          title: getStatusLabel("new"),
        },
      ],
      ordersStatus: null,
      orderStatusesInfo: null,
      orderStatusesInfo1: null,
      customers: [],
      products: [],
      showingTrackings: [],
    };
  },
  created() {
    if (this.$route.query.search) {
      let search = JSON.parse(this.$route.query.search);
      this.filter.tracking_code = search.tracking_code || "";
      this.filter.city = search.city || "";
      this.filter.province = search.province || "";
      this.filter.product_id = search.product_id || "";
      this.filter.variety_id = search.variety_id || "";
    }
  },
  mounted() {
    this.loadItems().then((res) => {
      this.orderStatusesInfo = res.data.data.order_statuses;
      this.orderStatusesInfo1 = res.data.data.order_statuses_total;
      this.gateways = res.data.data.gateways;
    });
    this.getShippings();
  },
  watch: {
    selectedOrders(newVal) {
      let selectedOrders = newVal.filter((item) => item != false);
      if (selectedOrders != "") {
        this.btnStatus = true;
      } else {
        this.btnStatus = false;
      }
    },
    widgetData(newVal) {
      this.customers = newVal.customers;
      this.products = newVal.products;
    },
    products(newVal) {
      if (!newVal) {
        return;
      }
      let products = newVal.forEach((product) => {
        product.title = "شناسه : " + product.id + " - " + product.title;
        return product;
      });
      return products;
    },
    customers(newVal) {
      let customers = newVal.forEach((item) => {
        item.full_name =
          (item.first_name ? item.first_name : "") +
          " " +
          (item.last_name ? item.last_name : "") +
          " " +
          item.mobile;
        return item;
      });
      return customers;
    },
    "filter.status": function(newVal) {
      if (typeof newVal === "string" || typeof newVal === "number") {
        this.filter.status = this.statusFilterOptions.find((item) => {
          return item.value == newVal;
        });
      }
    },
  },
  methods: {
    getShippings() {
      this.$axios.get("/admin/shippings?all=1").then((res) => {
        this.shippings = res.data.data.shippings.map((shipping) => {
          shipping.value = shipping.id;
          shipping.title = shipping.name;
          return shipping;
        });
        this.shippings.unshift({
          value: "",
          title: "همه",
        });
      });
    },
    getCustomerInfo(item) {
      if (item.customer_first_name) {
        return item.customer_first_name.concat(` ${item.customer_last_name}`);
      } else {
        return item.customer_mobile;
      }
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        if (typeof this.url !== "string") {
          return;
        }
        let page = this.$route.query.page || 1;
        // let apiType= "new";
        this.show = true;
        this.disabled = true;
        let search = {};
        if (this.$route.query.search) {
          search = JSON.parse(this.$route.query.search);
        }

        let query = "";
        if (this.extraQuery) {
          for (const key in this.extraQuery) {
            query = query + `${key}=${this.extraQuery[key]}&`;
          }
        }
        this.$axios
          .get(
            this.url +
              `${this.extraQuery != null ? "?" + query.slice(0, -1) : ""}`,
            {
              params: {
                ...this.params,
                page,
                // apiType,
                widgets: !this.widgetsAreLoaded
                  ? JSON.stringify(this.widgets)
                  : "",
                ...search,
              },
            }
          )
          .then((response) => {
            this.widgetsAreLoaded = true;
            // let objectKey = Object.keys(response.data.data)[0];
            this.items = response.data.data.orders;
            if (response.data.data.widgets) {
              this.widgetData = response.data.data.widgets;
            }
            this.show = false;
            this.disabled = false;
            if (response.data.data.store_rules) {
              this.createRules = response.data.data.store_rules;
            }
            if (response.data.data.update_rules) {
              this.editRules = response.data.data.update_rules;
            }

            this.orderStatusesInfo = response.data.data.order_statuses;
            this.orderStatusesInfo1 = response.data.data.order_statuses_total;
            resolve(response);
          })
          .catch((error) => {
            this.$root.notify(error);
            reject(error);
          });
      });
    },
    loadType() {
      this.params = {
        offset_year: this.moment().format("YYYY") - this.selectedYear,
        month: this.selectedMonth,
      };
      this.loadItems();
    },
    getStatusLabel,
    statusChangedByButton() {
      this.$nextTick(() => {
        this.filterItem();
      });
    },
    resetFilter() {
      this.$refs.productSearch.reset();
      this.filter = this.$options.data.apply(this).filter;
    },
    selectProduct(p) {
      this.filter.product_id = p ? p.id : null;
      this.filter.variety_id = null;
    },
    selectVariety(v) {
      this.filter.variety_id = v ? v.id : null;
    },
    showTrack(p) {
      if (!this.showingTrackings.includes(p)) {
        this.showingTrackings.push(p);
      }
    },
    hideTrack(p) {
      this.showingTrackings = this.showingTrackings.filter((_p) => {
        return _p !== p;
      });
    },
    // getReceiverName(order) {
    //   let address;
    //   try {
    //     address = JSON.parse(order.address);
    //   } catch (e) {
    //     return "نامشخص";
    //   }
    //   return address.first_name + " " + address.last_name;
    // },
    orderSelect(index, id) {
      if (!this.selectedOrders[index]) {
        this.$set(this.selectedOrders, index, id);
      } else {
        this.$set(this.selectedOrders, index, false);
      }
    },
    orderSelectAll() {
      if (this.allSelected) {
        document
          .querySelectorAll("input[notselected]")
          .forEach((e) => e.click());
      } else {
        document.querySelectorAll("input[selected]").forEach((e) => e.click());
      }
    },
    async filterItem(page = 1) {
      this.searchParams = {
        first_name: this.filter.first_name,
        last_name: this.filter.last_name,
        tracking_code: this.filter.tracking_code,
        city: this.filter.city,
        province: this.filter.province,
        id: this.filter.id,
        // searchBy1: "id",
        customer_id: this.filter.customer ? this.filter.customer.id : "",
        // searchBy2: "customer_id",
        status: this.filter.status ? this.filter.status.value : "",
        // searchBy3: "status",
        product_id: this.filter.product_id,

        variety_id: this.filter.variety_id,
        start_date: this.filter.start_date
          ? window.moment(this.filter.start_date, "YYYY.MM.DD").unix()
          : "",
        end_date: this.filter.end_date
          ? window.moment(this.filter.end_date, "YYYY.MM.DD").unix()
          : "",
        shipping_id: this.filter.shipping_id
          ? this.filter.shipping_id.value
          : "",
        gateway_name: this.filter.gateway_name,
      };
    },
    newOrder() {
      this.$root.$emit("bv::show::modal", "order-modal");
    },
    printOrders(page = "print-orders") {
      let selectedOrders = this.selectedOrders.filter((item) => item != false);
      selectedOrders = selectedOrders.join(",");
      let routeData = this.$router.resolve({
        path: `/admin/${page}`,
        query: { ids: selectedOrders },
      });
      window.open(routeData.href, "_blank");
    },
    async updateStatus() {
      this.disabled = true;
      let selectedOrders = this.selectedOrders.filter((item) => item != false);
      try {
        const response = await this.$axios.post("admin/orders/status/changes", {
          status: this.ordersStatus ? this.ordersStatus.value : "",
          ids: selectedOrders,
        });
        this.selectedOrders.forEach((order, index) => {
          if (!order) {
            return;
          }
          this.items.data[index].status = this.ordersStatus.value;
          order = false;
        });
        document.querySelectorAll("input[selected]").forEach((e) => e.click());
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "change-status");
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
    getSumWalletInvoices(walletInvoices) {
      return walletInvoices.reduce(
        (a, b) => a + (b.type === "wallet" ? b.amount : b.wallet_amount),
        0
      );
    },
  },
  computed: {
    getFilteredData() {
      return this.items.data.filter((item) => {
        return item.parent_id == null;
      });
    },
    totalActiveOrders() {
      let activeStatuses = ["new", "in_progress", "delivered"];
      return this.orderStatusesInfo
        ? Object.keys(this.orderStatusesInfo)
            .filter((statusName) => activeStatuses.includes(statusName))
            .map((status) => parseInt(this.orderStatusesInfo[status]))
            .reduce((prev, current) => prev + current, 0)
        : 0;
    },
    totalActiveOrders1() {
      let activeStatuses = ["new", "in_progress", "delivered"];
      return this.orderStatusesInfo1
        ? Object.keys(this.orderStatusesInfo1)
            .filter((statusName) => activeStatuses.includes(statusName))
            .map((status) => parseInt(this.orderStatusesInfo1[status]))
            .reduce((prev, current) => prev + current, 0)
        : 0;
    },
  },
};
