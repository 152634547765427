import Back from '@@/core/components/shared/Back/Back'
import customerForm from '@@/core/components/customer/CustomerForm/CustomerForm'

export function customerFactory() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    national_code: '',
    gender: null,
    cart_number: '',
    birth_date: '',
    newsletter: 0,
    digipay:1,
    foreign_national: 0
  }
}

export default {
  components: {
    Back,
    customerForm
  },
  data() {
    return {
      title: '',
      url: '',
      axiosCancelSource: null,
      customer: customerFactory()
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = 'admin/customers/' + this.$route.params.id
        this.getCustomer()
    } else {
      this.$refs.customerForm.setDisabled(false)
      this.url = 'admin/customers'
    }
  },
  methods: {
    async getCustomer() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token
        })
        this.customer = response.data.data[0]
        if (this.customer.gender == 'male') {
          this.customer.gender = {
            value: 'male',
            name: 'مرد'
          }
        } else {
          this.customer.gender = {
            value: 'female',
            name: 'زن'
          }
        }
        this.$refs.customerForm.setDisabled(false)
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error)
      }
    }
  }
}
