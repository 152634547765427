export default {
  data() {
    return {
      filter: {
        selectedCustomer: null,
        statuses: [
          {"value": "delivered", "title": "ارسال شده"},
          {"value": "in_progress", "title": "در حال پردازش "},
          {"value": "new", "title": "در انتظار تکمیل"},
          {"value": "reserved", "title": "رزرو شده"}
        ],
        selectedCategories: [],
        area: {
          province: null,
          city: null
        },
        date: {
          startDate: '',
          endDate: ''
        },
        gateway_name:"",
        totalRange: [0, 100],
        itemsCountRange: [0, 100]
      },
    }
  },
  methods: {
    getFilters() {
      let data = {
        statuses: this.filter.statuses.map(s => s.value),
        province_id: this.filter.area.province ? this.filter.area.province.id : null,
        city_id: this.filter.area.city ? this.filter.area.city.id : null,
        category_ids: this.filter.selectedCategories.map(c => c.id),
        start_date: this.filter.date.startDate,
        end_date: this.filter.date.endDate,
        gateway_name: this.filter.gateway_name,

        customer_id: this.filter.selectedCustomer ? this.filter.selectedCustomer.id : null,
      };
      let rangesChanged = !(this.filter.totalRange[0] == 0 && this.filter.totalRange[1] == 100
          && this.filter.itemsCountRange[0] == 0 && this.filter.itemsCountRange[1] == 100);
      if (rangesChanged) {
        data.total_lower = this.filter.totalRange[0]
        data.total_higher = this.filter.totalRange[1]
        data.items_count_lower = this.filter.itemsCountRange[0]
        data.items_count_higher = this.filter.itemsCountRange[1]
      }

      return data
    }
  }
}
