<template>
  <main class="mt-5">
    <div
      class="d-flex justify-content-between"
      style="background-color: white;padding: 5px;border-radius: 5px;"
    ></div>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold py-3 d-flex align-items-center">
          تراکنش های دیجی پی
        </h5>
        <div style="flex:1"></div>

        <ExcelDownload class="mr-2" :extraQuery="extraQuery" :url="url" />
      </div>
      <b-form @submit="filterData" class="row align-items-center p-2 m-2">
        <b-form-group class="col-md-3" label=" از تاریخ:">
          <date-picker 
               format="YYYY-MM-DD"
          display-format="jYYYY-jMM-jDD"
          v-model="filter.from_date" />
        </b-form-group>
        <b-form-group class="col-md-3" label=" تا تاریخ:">
          <date-picker 
               format="YYYY-MM-DD"
          display-format="jYYYY-jMM-jDD"
          v-model="filter.to_date" />
        </b-form-group>
        <b-form-group class="col-md-3" label="شماره سفارش">
          <b-form-input v-model="filter.order_id"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-3" label="کدرهگیری ارسال شده">
          <b-form-input v-model="filter.tracking_code"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-3" label="کدرهگیری دریافت شده">
          <b-form-input v-model="filter.response_tracking_code"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-3" label="Provider Id">
          <b-form-input v-model="filter.providerId"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-4" label="نام مشتری">
          <b-form-input v-model="filter.customerName"></b-form-input>
        </b-form-group>
        <b-button
          @click="filterData()"
          class="mt-2 h-auto"
          variant="primary"
          :disabled="disabled"
          >جستجو</b-button
        >
      </b-form>
      <div class="card-body pt-1">
        <div>
          <b-table
            v-if="items"
            responsive
            hover
            bordered
            :items="items.data"
            :fields="fields"
            :style="disabled && items && items.data ? 'opacity: 0.7' : ''"
          >
            <template #cell(amount)="row">
              {{ row.item.amount | price }}
            </template>
            <template #cell(created_at)="row">
              {{ row.item.created_at | persianDate }}
            </template>

            <template #cell(detail)="row">
              <router-link
                :to="{
                  name: 'order.detail',
                  params: { id: row.item.order_id },
                }"
              >
                <b-button variant="info">
                  جزئیات
                </b-button>
              </router-link>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import digipayTransactionsImpl from "@@/core/pages/digipayTransactions/digipayTransactionsImpl";

export default {
  extends: digipayTransactionsImpl,
};
</script>
<style scoped>
.badge {
  font-size: 14px;
}
.orders-tooltip {
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  right: -4px;
  top: 50%;
  transform: translate(100%, -50%);
  border-radius: 5px;
  background: #024c5c;
  display: flex;
  flex-direction: column;
  color: white;
}

.orders-tooltip span {
  margin: 4px 0;
}

.orders-tooltip:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 7px solid #0f6674;
}
</style>
