import {BTooltip} from "bootstrap-vue";
import Back from "@@/core/components/shared/Back/Back";

export default {
  name: "OrderHeader",
  components: {
    BTooltip, Back
  },
  props:{
    order:Object,
  },
  methods: {
 
    orderEdit() {
      this.$emit('order-edit')
    },
    printOrder() {
      this.$emit('print-order')
    }
  }
}
