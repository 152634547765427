<template>
  <div class="border rounded mt-4">
    <h5 class="bg-light text-center rounded py-2 font-bold">
      تراکنش های دیجی پی
    </h5>
    <div class="row px-4 pb-4">
      <b-table responsive hover bordered :items="list" :fields="fields">
        <template #cell(amount)="row">
          {{ row.item.amount | price }}
        </template>
        <template #cell(created_at)="row">
          {{ row.item.created_at | persianDate }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
export default {
  components: {
    BTable,
  },
  name: "digiPayTranscation",
  props: {
    list: Object,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "شناسه",
        },

        {
          key: "amount",
          label: "مبلغ",
        },
        {
          key: "providerId",
          label: "providerId",
        },
        {
          key: "tracking_code",
          label: "کدرهگیری ارسال شده",
        },
        {
          key: "response_tracking_code",
          label: "کد رهگیری دریافت شده",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
